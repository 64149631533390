<template>
  <div class="edit-address-module">
    <s-tab
      v-model="activeTab"
      type="auto"
      class="edit-address-tab-wrap"
    >
      <s-tab-item
        v-for="item in tabItems"
        :id="item.value"  
        :key="item.value"
      >
        {{ item.label }}
      </s-tab-item>
    </s-tab>

    <s-tab-container
      v-model="activeTab"
      class="tab-container-wrap"
    >
      <s-tab-container-item
        v-for="item in tabItems"
        :id="item.value"
        :key="item.value"
        class="tab-container-item"
      >
        <order-list
          :language="language"
          :data="item.value === '1' ? orderMergeList : orderWrapList"
          :tab-type="item.value === '1' ? 1 : 2"
          :current-order="currentOrder"
        />
      </s-tab-container-item>
    </s-tab-container>
  </div>
</template>

<script>
import { STabContainerItem } from '@shein-aidc/sui-tab-container-item/mobile'
import { STabContainer } from '@shein-aidc/sui-tab-container/mobile'
import { STabItem } from '@shein-aidc/sui-tab-item/mobile'
import { STab } from '@shein-aidc/sui-tab/mobile'
import { defineComponent } from 'vue'
import OrderList from './OrderList.vue'

export default defineComponent({
  name: 'TabContainer',
  components: {
    OrderList,
    STab,
    STabItem,
    STabContainer,
    STabContainerItem,
  },
  props: {
    language: {
      type: Object,
      default: () => {},
    },
    orderMergeList: {
      type: Array,
      default: () => [],
    },
    orderWrapList: {
      type: Array,
      default: () => [],
    },
    currentOrder: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      activeTab: '1',
    }
  },
  computed: {
    tabItems() {
      return [
        {
          label: this.language.SHEIN_KEY_PWA_26592,
          value: '1',
        },
        {
          label: this.language.SHEIN_KEY_PWA_26593,
          value: '2',
        },
      ]
    },
  },
})
</script>
<style lang="less">
.edit-address-module {
  display: flex;
  flex-direction: column;
  height: 100%;

  .tab-container-wrap {
    flex: 1 1 0;
    .sui-tab-container__inner {
      height: 100%;
    }
    .tab-container-item {
      overflow-y: auto;
    }
  }
}
</style>
