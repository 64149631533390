<template>
  <div
    class="order-merge-item"
    :class="{ 'show-border': border }"
  >
    <div class="order-merge-item__header">
      <p
        v-if="showTime"
        class="order-merge-item__time"
      >
        <span class="order-merge-item__time-name">
          {{ language.SHEIN_KEY_PWA_25771 }}
        </span>
        <span class="order-merge-item__time-desc">
          {{ handlerDate(item.add_time) }}
        </span>
      </p>
      <p class="order-merge-item__title">
        <span class="order-merge-item__number">
          {{ language.SHEIN_KEY_PWA_16822 }} {{ item.billno }}
        </span>
        <s-label
          v-if="showOneClickPayTag"
          type="success"
        >
          {{ language.SHEIN_KEY_PWA_23937 }}
        </s-label>
        <s-label
          v-if="showCurrentOrderTag"
          type="neutral"
        >
          {{ language.SHEIN_KEY_PWA_14592 }}
        </s-label>
      </p>
    </div>

    <div class="order-merge-item__list">
      <div
        v-for="(product, index) in item.goods_list"
        :key="index"
        class="order-merge-item__item"
      >
        <img
          :data-src="transformImg({ img: product.goods_thumb })"
          class="lazyload"
        />
        <span class="order-merge-item__total">x{{ product.goods_num }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { transformImg } from '@shein/common-function'
import orderLogic from 'public/src/pages/common/orderLogic'
import { SLabel } from '@shein-aidc/sui-label/mobile'

export default defineComponent({
  name: 'OrderMergeItem',
  components: {
    SLabel
  },
  emits: ['update:visible'],
  props: {
    visible: {
      type: Object,
      default: () => {},
    },
    language: {
      type: Object,
      default: () => {},
    },
    item: {
      type: Object,
      default: () => {},
    },
    border: {
      type: Boolean,
      default: false,
    },
    showTime: {
      type: Boolean,
      default: true,
    },
    showOneClickPayTag: {
      type: Boolean,
      default: false,
    },
    showCurrentOrderTag: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    transformImg,
    updateVisible(event) {
      this.$emit('update:visible', event)
    },
    handlerDate(date) {
      return orderLogic.orderDateFormat(date, true)
    }
  },
})
</script>
<style lang="less" scoped>
.order-merge-item {
  position: relative;
  padding: 24/75rem;
  background: @sui_color_white;

  :deep(.sui-label-common) {
    margin-left: 8/75rem;
  }
  &.show-border:after {
    content: '';
    position: absolute;
    top: 0;
    left: 24/75rem;
    right: 0;
    border-top: 1px solid @sui_color_gray_weak1;
  }

  &__header {
    font-family: 'SF UI Text';
    font-size: 12px;
    color: @sui_color_gray_dark1;
  }

  &__time {
    color: @sui_color_gray_dark2;
    margin-bottom: 16/75rem;
  }

  &__title {
    margin-bottom: 16/75rem;
  }

  &__list {
    display: block;
    white-space: nowrap;
    overflow-x: auto;
    scroll-behavior: smooth;
    overflow-y: hidden;
  }

  &__item {
    display: inline-block;
    position: relative;
    width: 150/75rem;
    height: 196/75rem;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    & + & {
      margin-left: 16/75rem;
    }
  }

  &__total {
    position: absolute;
    right: 0;
    bottom: 0;
    font-size: 10px;
    color: @sui_color_gray_dark2;
    padding: 6/75rem;
    background: rgba(255, 255, 255, 0.9);
  }
}
</style>
