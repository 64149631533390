<template>
  <s-dialog
    :visible="visible"
    :show-close="true"
    :append-to-body="true"
    class="edit-address-confirm-dialog"
    @update:visible="updateVisible"
    @opened="exposeConfirmDialog"
  >
    <div class="edit-address-confirm-dialog__tips">
      {{
        batchModifyAddress.modify_type == 1
          ? language.SHEIN_KEY_PWA_26586
          : language.SHEIN_KEY_PWA_26589
      }}
    </div>
    <template #footer>
      <div class="edit-address-confirm-dialog__footer">
        <template v-if="batchModifyAddress.modify_type == 1">
          <s-button
            v-tap="{
              id: '1-20-1-79',
              data: {
                order_no: billno,
                modify_type: batchModifyAddress.modify_type,
                cross_type: 0
              }
            }"
            class="edit-address-confirm-btn"
            :type="['primary', 'H72PX']"
            @click="onConfirm(0)"
          >
            {{ language.SHEIN_KEY_PWA_26587 }}
          </s-button>

          <div
            v-if="!isUsCombined"
            class="view-detail"
          >
            <span
              v-tap="{
                id: '1-20-1-80',
                data: {
                  order_no: billno
                }
              }"
              class="view-detail__btn"
              @click="viewDetails"
            >
              {{ language.SHEIN_KEY_PWA_26588 }}
            </span>
          </div>
        </template>
        <template v-else-if="batchModifyAddress.modify_type == 2">
          <s-button
            v-tap="{
              id: '1-20-1-79',
              data: {
                order_no: billno,
                modify_type: batchModifyAddress.modify_type,
                cross_type: 0
              }
            }"
            class="edit-address-confirm-btn"
            :type="['primary', 'H72PX']"
            @click="onConfirm(0)"
          >
            {{ language.SHEIN_KEY_PWA_26587 }}
          </s-button>

          <s-button
            v-if="!isUsCombined"
            v-tap="{
              id: '1-20-1-79',
              data: {
                order_no: billno,
                modify_type: batchModifyAddress.modify_type,
                cross_type: 1
              }
            }"
            class="edit-address-confirm-btn"
            :type="['H72PX']"
            @click="onConfirm(1)"
          >
            {{ language.SHEIN_KEY_PWA_26590 }}
          </s-button>

          <div
            v-if="!isUsCombined"
            class="view-detail"
          >
            <span
              v-tap="{
                id: '1-20-1-80',
                data: {
                  order_no: billno
                }
              }"
              class="view-detail__btn"
              @click="viewDetails"
            >
              {{ language.SHEIN_KEY_PWA_26596 }}
            </span>
          </div>
        </template>
      </div>
    </template>

    <order-merge-drawer
      v-model:visible="showOrderMergeDrawer"
      :language="language"
      :billno="billno"
      :batch-modify-address="batchModifyAddress"
    />
  </s-dialog>
</template>

<script>
import { defineComponent } from 'vue'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { tap, expose } from 'public/src/pages/common/analysis/directive.js'
import OrderMergeDrawer from './OrderMergeDrawer.vue'
import { SButton } from '@shein-aidc/sui-button/mobile'
import { SDialog } from '@shein-aidc/sui-dialog/mobile'

daEventCenter.addSubscriber({ modulecode: '1-20-1' })

export default defineComponent({
  name: 'EditAddressConfirmDialog',
  directives: { tap, expose },
  components: {
    OrderMergeDrawer,
    SDialog,
    SButton
  },
  emits: ['update:visible', 'confirm'],
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    batchModifyAddress: {
      type: Object,
      default: () => {}
    },
    language: {
      type: Object,
      default: () => {}
    },
    billno: {
      type: String,
      default: ''
    },
    isUsCombined: {
    // 是否美国三仓合流
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showOrderMergeDrawer: false,
      prevOrderNo: ''
    }
  },
  methods: {
    updateVisible(event) {
      this.$emit('update:visible', event)
    },
    onConfirm(crossType) {
      this.updateVisible(false)
      this.$emit('confirm', crossType)
    },
    viewDetails() {
      this.showOrderMergeDrawer = true
    },
    exposeConfirmDialog() {
      if (this.prevOrderNo !== this.billno) {
        this.prevOrderNo = this.billno
        daEventCenter.triggerNotice({
          daId: '1-20-1-78',
          extraData: {
            order_no: this.billno,
            modify_type: this.batchModifyAddress.modify_type
          }
        })
      }
    }
  },
})
</script>
<style lang="less">
/* stylelint-disable selector-class-pattern, selector-max-specificity, selector-max-type  */
.edit-address-confirm-dialog {
  &__footer {
    .view-detail {
      margin-top: 24/75rem;
    }
  }

  .view-detail {
    text-align: center;

    &__btn {
      font-family: 'SF UI Text';
      font-size: 14px;
      color: @sui_color_link;
    }
  }

  .edit-address-confirm-dialog__footer {
    .edit-address-confirm-btn {
      display: block;
      width: 100%;
      font-size: 12px;
    }
  }

  .edit-address-confirm-btn + .edit-address-confirm-btn {
    margin-top: 24/75rem;
  }
}
</style>
